import React, { createContext, useEffect, useMemo, useState } from "react";
import "./App.css";
import { BookInterface, Home } from "./components/Home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import { AboutUs } from "./components/AboutUs";
import { AllCourses } from "./components/AllCourses";
import { BodyWrapper } from "./components/BodyWrapper";
import { SurveillanceCourses } from "./components/SurveillanceCourses";
import { InvestigationsCourses } from "./components/InvestigationsCourses";

import { Services } from "./components/Services/Services";
import { IntelligenceCourses } from "./components/IntelligenceCourses";
import { IntelligenceCourse1 } from "./components/IntelligenceCourses/IntelligenceCourse1";
import { IntelligenceCourse2 } from "./components/IntelligenceCourses/IntelligenceCourse2";
import { InvestigationsCourse1 } from "./components/InvestigationsCourses/InvestigationsCourse1";
import { InvestigationsCourse3 } from "./components/InvestigationsCourses/InvestigationsCourse3";
import { Faq } from "./components/Faq";
import { BlogComponent } from "./components/BlogComponent";
import "@iconscout/unicons/css/line.css";
import "./assets/css/plugins.css";
import "./assets/css/style.css";
import { ContactUs } from "./components/ContactUs";
import { AllBooks } from "./components/BooksComponents/AllBooks";
import { Book } from "./components/BooksComponents/Book";
import { BooksOutlet } from "./components/BooksComponents/BooksOutlet";
import { Basket } from "./components/BasketComponent/Basket";
import { CheckoutPage } from "./components/CheckoutPage";
import { Login } from "./components/Auth/Login";
import { Register } from "./components/Auth/Register";
import { ForgotPassword } from "./components/Auth/ForgotPassword";
import { PasswordRecovery } from "./components/Auth/PasswordRecovery";
import { BasketProvider } from "./components/Context/BasketContext";
import { SurveillanceCourse6 } from "./components/SurveillanceCourses/SurveillanceCourse6";
import { SurveillanceCourse8 } from "./components/SurveillanceCourses/SurveillanceCourse8";
import { Investigations } from "./components/Services/Investigations";
import { Consultancy } from "./components/Services/Consultancy";
import { TermsAndConditions } from "./components/Legal/TermsAndConditions";
import { Disclaimer } from "./components/Legal/Disclaimer";
import { PrivacyPolicy } from "./components/Legal/PrivacyPolicy";
import { CopyrightPolicy } from "./components/Legal/CopyrightPolicy";
import { CookiePolicy } from "./components/Legal/CookiePolicy";
import BlogDetail from "./components/BlogComponent/BlogDetail";
import blogData from "./components/BlogComponent/blogData";
import { OnlineCourses } from "./components/OnlineCourses/OnlineCourses";
import { OnlineCourse } from "./components/OnlineCourses/OnlineCourse";
import { onlineCoursesData } from "./components/OnlineCourses/OnlineCoursesData";
import { UserProvider } from "./components/Context/UserContext";
import { MyCourses } from "./components/OnlineCourses/MyCourses";
import { SuccessPayment } from "./components/SuccessPayment";

function App() {
  const [basketItemsNumber, setBasketItemsNumber] = useState(0);
  const value = useMemo(
    () => ({ basketItemsNumber, setBasketItemsNumber }),
    [basketItemsNumber]
  );
  const books = [
    {
      id: 1,
      link: "/isa-books/1",
      imgSrc: "../assets/img/photos/book1.webp",
      title: "The Geru Method Vol.1",
      content:
        "<strong>The book COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 1 </strong>analyses the covert surveillance activity on 3 levels: \n" +
        "<strong>1. Theory \n" +
        "2. Application of theory at high level with minimum 5 cars and 1 VAN \n" +
        "3. Substantiation of theory</strong>, its application and the author's experience in cases of real actions explained with working variants, risk variants, risk avoidance methods, unforeseen events in the field and their remedy. No word is put outside the specialization of covert surveillance, no sentence refers to anything else. Everything is compressed to provide a huge amount of knowledge without reading things that do not concern this specialization.",
      price: 34.74,
      currency: "GBP",
    },
    {
      id: 2,
      link: "/isa-books/2",
      imgSrc: "../assets/img/photos/book2.webp",
      title: "The Geru Method Vol.2",
      content:
        "The book <strong>COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 2 </strong>is the special part of covert surveillance that, after assimilating what has been mentioned, enables us to move on to understanding high-risk actions, where working environments change, dealing with surveillance in the rural environment which still eludes the majority of the professionals, explaining how to carry out surveillance on all the continents, the specifics of each and the methods indicated for each specific environment. We also tackle almost impossible actions in seaside resorts, in the mountains where the target is very difficult to monitor.",
      price: 34.74,
      currency: "GBP",
    },
    {
      id: 3,
      link: "/isa-books/3",
      imgSrc: "../assets/img/photos/bothBooks.webp",
      title: "The Geru Method Vol.1 + Vol.2",
      content:
        "<strong>The book COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 1 </strong>analyses the covert surveillance activity on 3 levels: \n" +
        "<strong>1. Theory \n" +
        "2. Application of theory at high level with minimum 5 cars and 1 VAN \n" +
        "3. Substantiation of theory</strong>, its application and the author's experience in cases of real actions explained with working variants, risk variants, risk avoidance methods, unforeseen events in the field and their remedy. No word is put outside the specialization of covert surveillance, no sentence refers to anything else. Everything is compressed to provide a huge amount of knowledge without reading things that do not concern this specialization." +
        "\n+ \n The book <strong>COVERT SURVEILLANCE BEST PRACTICE - THE GERU METHODS - VOLUME 2 </strong>is the special part of covert surveillance that, after assimilating what has been mentioned, enables us to move on to understanding high-risk actions, where working environments change, dealing with surveillance in the rural environment which still eludes the majority of the professionals, explaining how to carry out surveillance on all the continents, the specifics of each and the methods indicated for each specific environment. We also tackle almost impossible actions in seaside resorts, in the mountains where the target is very difficult to monitor.",
      price: 65,
      currency: "GBP",
    },

    {
      id:4,
      link: "/isa-books/4",
      imgSrc: "../assets/img/photos/book3.webp",
      title: "The Geru Method Vol.3",
      content: `
  <p>The Manual of Cover Surveillance Mistakes provides invaluable insights into the complexities and pitfalls of surveillance operations, with examples where errors compromised security, exposed agents, or damaged the credibility of intelligence agencies. Typical mistakes in covert surveillance include insufficient background research, poor communication, over-reliance on technology, and failure to anticipate human error.</p>
  
  <p>One common error is underestimating the importance of a thorough background check. Surveillance operations sometimes fall short by not gathering enough intelligence on suspects or the environment, which can lead to critical blind spots. Another significant issue is poor communication within and between teams. In several cases, agencies have failed to properly relay information, leading to missed opportunities or contradictory actions that jeopardized missions.</p>
  
  <p>Over-reliance on technology is another recurring theme. While advanced technology can enhance surveillance capabilities, it can also create dependencies, leaving operations vulnerable if equipment fails or if adversaries develop counter-surveillance measures. Finally, human error remains a leading cause of surveillance mishaps. Complacency, lack of training, or psychological pressures can lead operatives to make avoidable mistakes.</p>
  
  <p>To prevent these mistakes, the book recommends rigorous planning, realistic scenario training, and continuous reassessment of protocols. It also emphasizes adaptability—ensuring that operatives can think on their feet and respond to unexpected developments. Improving communication channels and ensuring all team members have access to critical intelligence are other key measures. Finally, the book stresses the importance of balancing human intelligence with technological tools to avoid reliance on any single source. By learning from past mistakes, surveillance professionals can conduct more effective, secure, and discreet operations in the future, minimizing risks and enhancing overall mission success.</p>
`,
      price: 32,
      currency: "GBP",
    },
    {
      id:5,
      link: "/isa-books/5",
      imgSrc: "../assets/img/photos/book4.webp",
      title: "The Geru Method Vol.4",
      content:
          `<p>Foot surveillance is at the heart of surveillance. If this activity is not carried out properly, the entire action is compromised. For this reason, we considered it would be in the interest of professionals that, after acquiring the knowledge from Volumes 1 and 2 on covert surveillance, we pick each part of this matter and show what is correct and what is incorrect in carrying out this activity.</p>
          <p>In this manual, we will refer to foot surveillance carried out in teams of 1, 2 and a team of investigators.</p>
          <p>We will analyse how to carry out foot surveillance in all environmental conditions, uncrowded, crowded, moderately crowded streets, the correct execution method and the mistakes made in the field.</p>`,
      price: 31,
      currency: "GBP",
    },
    {
      id:6,
      link: "/isa-books/6",
      imgSrc: "../assets/img/photos/pack-books3_4.webp",
      title: "The Geru Method Vol.3 + Vol.4",
      content:
          "This pack contains <strong>The Geru Method Volumes 3 and 4</strong>, offering advanced insights on surveillance techniques and methodologies, focusing on avoiding common mistakes and conducting effective foot surveillance in various environmental conditions.",
      price: 60,
      currency: "GBP",
    },
    {
      id:7,
      link: "/isa-books/7",
      imgSrc: "../assets/img/photos/bothBooks.webp",
      title: "The Geru Method Vol.1 to Vol.4 Pack",
      content: `<p>This pack contains all the first 4 volumes of the Geru Method series, providing a comprehensive guide to surveillance operations:</p>
          <ul>
              <li>The Geru Method Vol.1</li>
              <li>The Geru Method Vol.2</li>
              <li>The Geru Method Vol.3</li>
              <li>The Geru Method Vol.4</li>
          </ul>
          <p>Explore each volume with examples, strategies, and detailed methodologies designed for professionals looking to master covert surveillance in various environments, from urban settings to high-risk operations.</p>`,
      price: 120,
      currency: "GBP",
    },
    {
      id:8,
      link: "/isa-books/8",
      imgSrc: "../assets/img/photos/book5.webp",
      title: "The Geru Method Vol.5",
      content: `
  <p>The book <strong>"Manual of Mistakes and Solutions in Surveillance"</strong> will be published, focusing on surveillance in various settings such as slums, restaurants, bars, institutions, and malls.</p>
  <p>This book serves as a valuable continuation to previous publications, offering insights for analyzing and conducting operations in challenging scenarios.</p>
  <p>I have applied these techniques in both small and large-scale settings, enhancing the understanding of complex surveillance dynamics.</p>
  <p>Your interest in my posts is appreciated, and I urge you to delve deeper into this topic for comprehensive knowledge.</p>
`,
      price: 31,
      currency: "GBP",
    },
  ] as BookInterface[];

  const surveillanceCourses = [
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE FOR PROFESSIONALS ",
      link: "/isa-surveillance-course-6",
      img: "./assets/img/photos/f7.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - FOR THE SPECIALIZATION OF ORGANIZED CRIME, POLICE, CLOSE PROTECTION INTEGRATED AT GOVERNMENT LEVEL",
      link: "/isa-surveillance-course-8",
      img: "./assets/img/photos/f5.webp",
    },
  ];
  const investigationCourses = [
    {
      name: "CERTIFICATE IN COVERT SURVEILLANCE OPERAȚIONS - FOR FOR STUDENTS WITH BACKGROUND IN THE MILITARY, POLICE AND OTHER RELATED SPECIALIZATIONS",
      link: "/isa-investigations-course-3",
      img: "./assets/img/photos/f8.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE MANAGEMENT - FOR MANAGERS, DIRECTORS, TEAM LEADER",
      link: "/isa-investigations-course-1",
      img: "./assets/img/photos/f4.webp",
    },
  ];
  const intelligenceCourses = [
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / covert surveillance, intelligence for companies, corporations, enforcement organizations with Link duration of 3 months",
      link: "/isa-intelligence-course-1",
      img: "./assets/img/photos/f9.webp",
    },
    {
      name: "DEEPENING COURSE IN COVERT SURVEILLANCE - Organization of departments / structures / teams / personal protection structured in plain sight and covert with complex mixed covert surveillance procedures and intelligence",
      link: "/isa-intelligence-course-2",
      img: "./assets/img/photos/f10.webp",
    },
  ];

  const allCourses = [
    ...surveillanceCourses,
    ...investigationCourses,
    ...intelligenceCourses,
  ];
  return (
    <>
      {
        // @ts-ignore
        <BasketProvider value={value}>
          <UserProvider>
            <BrowserRouter>
              <Routes>
                <Route
                  path={"/" || "/Home"}
                  element={<BodyWrapper children={<Home books={books} />} />}
                />
                <Route
                  path={"/Home"}
                  element={<BodyWrapper children={<Home books={books} />} />}
                />
                <Route
                  path={"/login"}
                  element={<BodyWrapper children={<Login />} />}
                />

                <Route
                  path={"/register"}
                  element={<BodyWrapper children={<Register />} />}
                />
                <Route
                  path={"/forgot-password"}
                  element={<BodyWrapper children={<ForgotPassword />} />}
                />
                <Route
                  path={"/reset/:token"}
                  element={<BodyWrapper children={<PasswordRecovery />} />}
                />
                <Route
                  path={"/isa-about-us"}
                  element={<BodyWrapper children={<AboutUs />} />}
                />

                <Route
                  path={"/isa-all-training-courses"}
                  element={
                    <BodyWrapper
                      children={<AllCourses courses={allCourses} />}
                    />
                  }
                />
                <Route
                  path={"/isa-surveillance-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <SurveillanceCourses courses={surveillanceCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-surveillance-course-6"}
                  element={<BodyWrapper children={<SurveillanceCourse6 />} />}
                />
                <Route
                  path={"/isa-surveillance-course-8"}
                  element={<BodyWrapper children={<SurveillanceCourse8 />} />}
                />
                <Route
                  path={"/isa-investigations-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <InvestigationsCourses courses={investigationCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-investigations-course-1"}
                  element={<BodyWrapper children={<InvestigationsCourse1 />} />}
                />
                <Route
                  path={"/isa-investigations-course-3"}
                  element={<BodyWrapper children={<InvestigationsCourse3 />} />}
                />

                <Route
                  path={"/isa-intelligence-courses"}
                  element={
                    <BodyWrapper
                      children={
                        <IntelligenceCourses courses={intelligenceCourses} />
                      }
                    />
                  }
                />
                <Route
                  path={"/isa-intelligence-course-1"}
                  element={<BodyWrapper children={<IntelligenceCourse1 />} />}
                />
                <Route
                  path={"/isa-intelligence-course-2"}
                  element={<BodyWrapper children={<IntelligenceCourse2 />} />}
                />

                <Route
                  path={"isa-books"}
                  element={<BodyWrapper children={<BooksOutlet />} />}
                >
                  <Route
                    path={"all-books"}
                    element={<AllBooks books={books} />}
                  />
                  <Route path={":userId"} element={<Book books={books} />} />
                </Route>
                <Route
                  path={"isa-services"}
                  element={<BodyWrapper children={<Services />} />}
                />
                <Route
                  path={"isa-services/investigations"}
                  element={<BodyWrapper children={<Investigations />} />}
                />
                <Route
                  path={"isa-services/consultancy"}
                  element={<BodyWrapper children={<Consultancy />} />}
                />
                {/*<Route*/}
                {/*  path={"isa-faq"}*/}
                {/*  element={<BodyWrapper children={<Faq />} />}*/}
                {/*/>*/}
                <Route
                  path={"isa-blog"}
                  element={<BodyWrapper children={<BlogComponent />} />}
                />
                <Route
                  path={"contact"}
                  element={<BodyWrapper children={<ContactUs />} />}
                />
                <Route
                  path={"isa-basket"}
                  element={
                    <BodyWrapper
                      children={
                        <Basket
                          books={books}
                          onlineCourses={onlineCoursesData}
                        />
                      }
                    />
                  }
                />
                <Route
                  path={"terms-and-conditions"}
                  element={<BodyWrapper children={<TermsAndConditions />} />}
                />
                <Route
                  path={"disclaimer"}
                  element={<BodyWrapper children={<Disclaimer />} />}
                />
                <Route
                  path={"privacy-policy"}
                  element={<BodyWrapper children={<PrivacyPolicy />} />}
                />
                <Route
                  path={"copyright-policy"}
                  element={<BodyWrapper children={<CopyrightPolicy />} />}
                />
                <Route
                  path={"cookie-policy"}
                  element={<BodyWrapper children={<CookiePolicy />} />}
                />
                <Route
                  path={"checkout"}
                  element={
                    <BodyWrapper
                      children={
                        <CheckoutPage
                          books={books}
                          onlineCourses={onlineCoursesData}
                        />
                      }
                    />
                  }
                />
                <Route
                  path={"isa-online-courses"}
                  element={<BodyWrapper children={<OnlineCourses />} />}
                ></Route>
                <Route
                  path={"my-courses"}
                  element={
                    <BodyWrapper
                      children={<MyCourses onlineCourses={onlineCoursesData} />}
                    />
                  }
                ></Route>
                <Route
                  path={"payment-successful"}
                  element={<BodyWrapper children={<SuccessPayment />} />}
                ></Route>
                <Route
                  path={"isa-online-courses/:id"}
                  element={
                    <BodyWrapper
                      children={
                        <OnlineCourse onlineCourses={onlineCoursesData} />
                      }
                    />
                  }
                />
              </Routes>
            </BrowserRouter>
          </UserProvider>
        </BasketProvider>
      }
    </>
  );
}

export default App;
